import isBrowser from '~/core/generic/is-browser'

const env = isBrowser() ? window.ENV : process.env
const DEFAULT_LOCALE = env.DEFAULT_LOCALE ?? 'en'

const NAMESPACES = [
  'common',
  'auth',
  'organization',
  'profile',
  'subscription',
  'onboarding',
  'documents',
  'coding',
] as const

const i18Config = {
  fallbackLanguage: DEFAULT_LOCALE,
  supportedLanguages: [DEFAULT_LOCALE],
  defaultNS: NAMESPACES,
  react: { useSuspense: false },
}

export default i18Config
